import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { TableComponent } from "./table/table.component";
import { EditAppComponent } from "./edit-app/edit-app.component";
import { DndModule } from "ngx-drag-drop";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { FormsModule } from "@angular/forms";
import { AuthService } from "../auth/auth.service";

import { VoxeetComponent } from "./voxeet/voxeet.component";
import { AudioRecorderComponent } from "./audio-recorder/audio-recorder.component";
import { OnBoardingMessageComponent } from "./on-boarding-message/on-boarding-message.component";
import { PhonePickerComponent } from "./phone-picker/phone-picker.component";
import { ObjectValuesPipe } from "./pipes/objectValues";
import { TypeofPipe } from "./pipes/typeof";
import { DecodeHtmlStringPipe } from "./pipes/decode-html-string.pipe";
import { ChartsModule } from "ng2-charts";
import { ChartsComponent } from "./charts/charts.component";

@NgModule({
  declarations: [
    NavbarComponent,
    TableComponent,
    EditAppComponent,
    VoxeetComponent,
    AudioRecorderComponent,
    OnBoardingMessageComponent,
    PhonePickerComponent,
    ObjectValuesPipe,
    TypeofPipe,
    DecodeHtmlStringPipe,
    ChartsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SweetAlert2Module.forRoot(),
    DndModule,
    FormsModule,
    ChartsModule,
  ],
  exports: [
    NavbarComponent,
    TableComponent,
    EditAppComponent,
    VoxeetComponent,
    AudioRecorderComponent,
    OnBoardingMessageComponent,
    PhonePickerComponent,
    DecodeHtmlStringPipe,
    ChartsComponent,
  ],
  providers: [AuthService, TypeofPipe, DecodeHtmlStringPipe],
})
export class SharedModule {}
