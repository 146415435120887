export class AppConstants {
  public static CommunicationPlatForm = false;

  public static get baseURL(): string {
    //Production (WWTC)
    // return "https://apiforms.worldwidetechconnections.com/api";

    //QA (Nelumbo)
    return "https://formsapi.nelumbo.com.co/api";

    //Development (Nelumbo)
    // return "http://formsapidev.nelumbo.com.co/api";

    // return "http://localhost:3001/api";
  }
}
