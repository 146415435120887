import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NewFormComponent } from "./new-form/new-form.component";
import { UserFormsComponent } from "./user-forms/user-forms.component";
import { AuthGuard } from "./guards/auth.guard";
import { StatsComponent } from "./stats/stats.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: "new-form",
    component: NewFormComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./new-form/new-form.module").then((m) => m.NewFormModule),
      },
    ],
  },
  {
    path: "user-forms",
    component: UserFormsComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./user-forms/user-forms.module").then(
            (m) => m.UserFormsModule
          ),
      },
    ],
  },
  {
    path: "stats",
    component: StatsComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./stats/stats.module").then((m) => m.StatsModule),
      },
    ],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
