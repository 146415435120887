import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UserFormsRoutingModule } from "./user-forms-routing.module";
import { UserFormsComponent } from "./user-forms.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SharedModule } from "../shared/shared.module";
import { PublicFormComponent } from "./public-form/public-form.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { SignaturePadModule } from "angular2-signaturepad";
import { ScreenPublicComponent } from "./screen-public/screen-public.component";
import { DynamicFormService } from "../services/dynamic-form.service";
import { AnswerFormComponent } from "./answers/answer-form/answer-form.component";
import { LeadsFormComponent } from "./leads-form/leads-form.component";
import { AnswerDashboardComponent } from "./answers/answer-dashboard/answer-dashboard.component";

@NgModule({
  declarations: [
    UserFormsComponent,
    DashboardComponent,
    PublicFormComponent,
    ScreenPublicComponent,
    AnswerFormComponent,
    LeadsFormComponent,
    AnswerDashboardComponent
  ],
  imports: [
    CommonModule,
    UserFormsRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    SignaturePadModule
  ]
})
export class UserFormsModule {}
