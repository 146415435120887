import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-status-card",
  templateUrl: "./status-card.component.html",
  styleUrls: ["./status-card.component.scss"],
})
export class StatusCardComponent implements OnInit {
  @Input() data: any;

  constructor() {}

  ngOnInit() {}
}
