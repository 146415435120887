import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-phone-picker",
  templateUrl: "./phone-picker.component.html",
  styleUrls: ["./phone-picker.component.scss"]
})
export class PhonePickerComponent implements OnInit {
  public codeCountry: any;
  @Output() code = new EventEmitter();
  constructor() {
    this.codeCountry = "1";
  }

  ngOnInit() {}
  countryCode() {
    this.code.emit(this.codeCountry);
  }
}
