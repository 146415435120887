import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
  // ...
} from "@angular/animations";

@Component({
  selector: "app-on-boarding-message",
  templateUrl: "./on-boarding-message.component.html",
  styleUrls: ["./on-boarding-message.component.scss"],
  animations: [
    trigger("onBoardingStep", [
      transition(":enter", [
        style({ transform: "translateX(20%)", opacity: 0 }),
        animate("0.7s 300ms ease-in")
      ])
    ])
  ]
})
export class OnBoardingMessageComponent implements OnInit {
  @Output() option: EventEmitter<any> = new EventEmitter();
  @Input() index: number;
  @Input() dataOnBoarding: any;
  @Input() activateButton: any;
  constructor() {}

  ngOnInit() {}
  /**
   *
   * @param action
   * Emits the action to be performed by sending the information required by the parent component
   */
  action(action) {
    this.option.emit({
      option: action,
      valor: this.index,
      nextStep: this.dataOnBoarding[this.index].nextStep
    });
  }
}
