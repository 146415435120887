import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { AuthService } from "src/app/auth/auth.service";

import { formRow } from "../../models/formRow.model";
import { formCol } from "../../models/formCol.model";
import { colElement } from "../../models/colElement.model";

@Component({
  selector: "app-answer-form",
  templateUrl: "./answer-form.component.html",
  styleUrls: ["./answer-form.component.scss"]
})
export class AnswerFormComponent implements OnInit {
  public id;

  public jsonTheme: any;
  public theme: any;
  public arrayAnswer: any;
  public arrayRow: formRow[];
  public arrayCol: formCol[];
  public arrayElement: colElement[];
  public isBoxColorTransparent: boolean;
  public isLoading = true;
  public answerGeneralData;
  public logoAlign: "center";
  public isArabic;
  constructor(
    private route: ActivatedRoute,
    private _dynamicForm: DynamicFormService,
    private authService: AuthService
  ) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
    });

    this.isArabic = false;
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];
    this.isBoxColorTransparent = false;
    console.log(this.authService.getToken());

    this._dynamicForm
      .getAnswerbyToken(this.id, this.authService.getToken())
      .subscribe(
        response => {
          console.log(response);

          this.jsonTheme = response.data[0].answerData.theme;
          this.arrayAnswer = response.data[0].answerData.formRows;

          if (this.jsonTheme.parent == "parent") {
            this.theme = this.jsonTheme.nameTheme;
          } else {
            this.theme = this.jsonTheme.parent;
          }
          if (this.jsonTheme.dataTheme.logoAlign) {
            this.logoAlign = this.jsonTheme.dataTheme.logoAlign;
          }
          this.answerGeneralData = {
            publicTitle: response.data[0].answerData.publicTitle,
            instructions: response.data[0].answerData.instructions,
            logo_url: response.data[0].answerData.logo_url,
            signature_url: response.data[0].answerData.signature_url,
            language: response.data[0].answerData.language,
            idForm: response.data[0].answerData.id
          };
          if (this.answerGeneralData.code_language == "ar") {
            this.isArabic = true;
          }

          if (this.jsonTheme.dataTheme.boxColor == "transparent") {
            if (this.theme != "default") {
              this.isBoxColorTransparent = true;
              this.jsonTheme.dataTheme.boxColor = "#00000000";
            }
          } else {
            this.isBoxColorTransparent = false;
          }

          if (this.jsonTheme.dataTheme.boxColor == "#00000000") {
            if (this.theme != "default") {
              this.isBoxColorTransparent = true;
            }
          } else {
            this.isBoxColorTransparent = false;
          }
          this.loadArrayRow();
        },
        error => {
          console.log(error);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  ngOnInit() {}
  loadArrayRow() {
    for (let row = 0; row < this.arrayAnswer.length; row++) {
      this.arrayCol = [];
      for (let col = 0; col < this.arrayAnswer[row].formCol.length; col++) {
        this.arrayElement = [];
        for (
          let element = 0;
          element < this.arrayAnswer[row].formCol[col].colElement.length;
          element++
        ) {
          if (
            this.arrayAnswer[row].formCol[col].colElement[element]
              .elementTitle == "emphy"
          ) {
            this.arrayAnswer[row].formCol[col].colElement[
              element
            ].elementTitle = "";
          }
          if (
            this.arrayAnswer[row].formCol[col].colElement[element]
              .placeholder == "emphy"
          ) {
            this.arrayAnswer[row].formCol[col].colElement[element].placeholder =
              "";
          }

          this.arrayElement.push(
            this.arrayAnswer[row].formCol[col].colElement[element]
          );
        }
        this.arrayCol = [
          ...this.arrayCol,
          new formCol(
            col,
            row,
            this.arrayAnswer[row].formCol[col].percentage,
            this.arrayElement
          )
        ];
      }
      this.arrayRow = [...this.arrayRow, new formRow(row, 0, this.arrayCol)];
    }
  }
}
