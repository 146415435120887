import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as RecordRTC from "recordrtc";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from "src/app/services/api.service";
import { interval } from "rxjs";

@Component({
  selector: "app-audio-recorder",
  templateUrl: "./audio-recorder.component.html",
  styleUrls: ["./audio-recorder.component.scss"],
})
export class AudioRecorderComponent {
  //Lets initiate Record OBJ
  private record;
  //Will use this flag for detect recording
  private recording = false;
  //Url of Blob
  private url;
  private error;
  public codeLanguage;
  public pctVal: any;

  public isRequesting: boolean;

  @Input() tryDetectLang: boolean;
  @Input() arrayLang: any;
  @Output() emitCode: EventEmitter<any> = new EventEmitter();
  @Output() emitRecording: EventEmitter<any> = new EventEmitter();
  constructor(
    private domSanitizer: DomSanitizer,
    private apiService: ApiService
  ) {
    this.isRequesting = false;
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.tryDetectLang) {
      this.initiateRecording();
    }
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  /**
   * Start recording.
   */
  initiateRecording() {
    this.emitRecording.emit("true");
    this.update();

    this.url = "";
    this.recording = true;
    let mediaConstraints = {
      audio: true,
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream) {
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);

    this.isRequesting = true;

    if (this.arrayLang.length > 10) {
      this.apiService.detectlanguage(blob).subscribe(
        (response: any) => {
          this.isRequesting = false;
          // this.codeLanguage = response.sourceLanguage;

          this.codeLanguage = response.languageCode.substring(0, 2);
          console.log(this.codeLanguage);

          // response.sourceLanguage = "es";
          // this.codeLanguage = response.sourceLanguage;
        },
        (error) => {
          this.codeLanguage = "error";
        },
        () => {
          this.emitCode.emit(this.codeLanguage);
        }
      );
    } else {
      this.apiService.detectlanguage(blob, this.arrayLang).subscribe(
        (response: any) => {
          this.isRequesting = false;
          // this.codeLanguage = response.sourceLanguage;

          this.codeLanguage = response.languageCode.substring(0, 2);
          console.log(this.codeLanguage);

          // response.sourceLanguage = "es";
          // this.codeLanguage = response.sourceLanguage;
        },
        (error) => {
          this.codeLanguage = "error";
        },
        () => {
          this.emitCode.emit(this.codeLanguage);
        }
      );
    }
  }
  /**
   * Process Error.
   */
  errorCallback(error) {
    this.error = "Can not play audio in your browser";
  }

  update() {
    var element = document.getElementById("myprogressBar");
    var width = 1;
    this.pctVal = 1;

    const source = interval(75);
    const subscribe = source.subscribe(() => {
      if (width >= 100) {
        subscribe.unsubscribe();
        this.stopRecording();
      } else {
        width++;
        this.pctVal++;
        element.style.width = width + "%";
      }
    });
  }
}
