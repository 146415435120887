import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NewFormRoutingModule } from "./new-form-routing.module";
import { NewFormComponent } from "./new-form.component";
import { SharedModule } from "../shared/shared.module";
import { NewFormStep1Component } from "./step1/new-form-step1.component";
import { ConfigFormComponent } from "./config-form/config-form.component";
import { NewFormConfirmationComponent } from "./confirmation/new-form-confirmation.component";
import { NewFormStep2Component } from "./step2/new-form-step2.component";
import { PreviewFormComponent } from "./preview-form/preview-form.component";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { DndModule } from "ngx-drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { SignaturePadModule } from "angular2-signaturepad";
import { DynamicFormService } from "../services/dynamic-form.service";
import { NgxFileDropModule } from "ngx-file-drop";
import { NgxBootstrapSliderModule } from "ngx-bootstrap-slider";

@NgModule({
  declarations: [
    NewFormComponent,
    NewFormStep1Component,
    ConfigFormComponent,
    NewFormConfirmationComponent,
    NewFormStep2Component,
    PreviewFormComponent
  ],
  imports: [
    CommonModule,
    NewFormRoutingModule,
    SharedModule,
    NgxBootstrapSliderModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    DndModule,
    HttpClientModule,
    SignaturePadModule,
    NgxFileDropModule
  ]
})
export class NewFormModule {}
