import { StatsService } from "./../../stats/services/stats.service";
import { Component, Input, OnInit, HostBinding } from "@angular/core";
import { form } from "../models/form.model";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { AuthService } from "src/app/auth/auth.service";
import swal from "sweetalert2";
import { __core_private_testing_placeholder__ } from "@angular/core/testing";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from "@angular/animations";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/constants";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [
    trigger("withoutForm", [
      transition(":enter", [
        style({ transform: "translateY(-20%)", opacity: 0 }),
        animate("1s 300ms ease-in"),
      ]),
    ]),
  ],
})
export class DashboardComponent implements OnInit {
  dashboardEmpty: boolean;
  userHasForms: boolean;
  public forms: Array<form>;
  public dataToTable: Array<any>;
  public toSearch;
  public actualPage;
  public resultPerPage;
  public totalPage: Array<any>;
  public total;
  public tts: any;
  public mostrar: Array<any>;
  public nextAndPrevius: Array<any>;
  public band: boolean;
  public disableNext: boolean;
  public disablePrevius: boolean;
  public checkTts: boolean;
  public timer: any;
  public timerClipboard: any;
  public timerSession: any;
  public linkClipboard = false;
  public secondsClipboard = 4;
  public mobile: boolean;

  public headersTable = [
    {
      text: "Name",
      sort: true,
    },
    {
      text: "Last Update",
      sort: true,
    },
    {
      text: "Answers",
      sort: true,
    },
    {
      text: "Status",
      sort: true,
    },
    {
      text: "Actions",
      sort: false,
    },
  ];
  public actionsColumns = [
    "getFormByPublicLink",
    "getFormByPublicLink",
    "getFormByPublicLink",
    "getFormByPublicLink",
    "",
  ];
  constructor(
    private _dynamicFormService: DynamicFormService,
    private _router: Router,
    private authService: AuthService,
    private statsService: StatsService
  ) {}

  ngOnInit() {
    this.dataToTable = [];
    this._dynamicFormService.formPreview = null;
    this.nextAndPrevius = [];
    this.actualPage = 1;
    this.resultPerPage = 5;
    this.dashboardEmpty = false;
    this.userHasForms = false;
    this.checkTts = true;
    this.band = true;
    this.disableNext = false;
    this.disablePrevius = false;
    this.mobile = false;
    this.nextAndPrevius.push(this.actualPage);
    this.onResize();
    this.getForms();

    this.checkTranslation();

    this.timerSession = setInterval(() => {
      this.statsService.sessionTime++;
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  actionTable(e) {
    console.log(e);
    if (e.action == "getFormByPublicLink") {
      this._router.navigate([
        "/user-forms/public-form/" + this.forms[e.index].public_link,
      ]);
    }

    if (e.action == "copyLink") {
      this.copyLink(this.forms[e.index].public_link);
      this.linkClipboard = true;
      this.secondsClipboard = 4;
      this.timerClipboard = setInterval(() => this.closeClipboard(), 1000);
    }

    if (e.action == "downloadPdf") {
      let ip = window.location.origin;
      let baseURL = AppConstants.baseURL;

      let prod = false;
      if (baseURL === "https://apiforms.worldwidetechconnections.com/api")
        prod = true;

      let url =
        "https://document.nelumbo.com.co/api/downloadPdfs/" +
        this.forms[e.index].public_link +
        "/" +
        prod;

      window.open(url, "_blank");
    }

    if (e.action == "goToLeadsDashboard") {
      this._router.navigate(["/user-forms/leads/" + this.forms[e.index].id]);
    }

    if (e.action == "goToAnwersDashboard") {
      this._router.navigate([
        "/user-forms/answers-dashboard/" + this.forms[e.index].id,
      ]);
    }

    if (e.action == "editForm") {
      this._router.navigate(["/new-form/step1/" + this.forms[e.index].id]);
    }
    if (e.action == "deleteForm") {
      this.deleteForm(this.forms[e.index].id);
    }
    if (e.action == "statusChange") {
      this._dynamicFormService
        .statusChange(this.forms[e.index].id, this.authService.getToken())
        .subscribe(
          (response) => {
            this.forms[e.index]["statusForm"] = response.data.statusForm;
            this.loadDataTable(this.forms);
          },
          (error) => {}
        );
    }
  }

  /**
   * Get the forms by user
   */
  getForms() {
    this._dynamicFormService
      .getJSONSpagination(
        this.authService.getToken(),
        this.actualPage,
        this.resultPerPage
      )
      .subscribe(
        (response) => {
          this.totalPage = new Array(response.data.last_page);

          if (response.data.total > 0) this.userHasForms = true;
          else this.dashboardEmpty = true;

          if (response.data.last_page > 4) {
            this.mostrar = new Array(5);
          } else {
            this.mostrar = new Array(response.data.last_page);
          }
          for (let index = 0; index < this.mostrar.length; index++) {
            this.mostrar[index] = index + 1;
          }
          if (this.actualPage > 5) {
            console.log(this.mostrar);

            for (let index = 0; index < this.mostrar.length; index++) {
              this.mostrar[index] = this.mostrar[index] + 1;
            }
          }

          if (this.actualPage == 1) {
            this.disablePrevius = true;
          } else {
            this.disablePrevius = false;
          }

          if (this.actualPage === this.totalPage.length) {
            this.disableNext = true;
          } else {
            this.disableNext = false;
          }
          this.forms = response.data.Items;

          this.actionsColumns = [];
          for (let index = 0; index < this.forms.length; index++) {
            this.actionsColumns.push("getFormByPublicLink");
          }

          this.loadDataTable(this.forms);

          for (let index = 0; index < this.forms.length; index++) {
            this.tts = this.forms[index];

            if (this.tts.tts === false) {
              this.checkTts = true;

              break;
            } else {
              this.checkTts = false;
            }
          }
          if (this.checkTts == false) {
            clearInterval(this.timer);
          }

          if (response.data.LastEvaluatedKey) {
            this.actualPage = response.data.LastEvaluatedKey.id;
            this.nextAndPrevius.push(this.actualPage);
          }
        },
        (error) => {}
      );
  }

  /**
   * Checks if all forms are translated
   */
  checkTranslation() {
    this.timer = setInterval(() => this.getForms(), 30000);
  }

  /**
   * Sets the number of results per page in the dashboard
   */
  perPage(number) {
    this.resultPerPage = number;
    this.actualPage = 1;
    this.getForms();
  }

  /**
   * Goes to the selected page
   */
  page(page) {
    this.actualPage = page;

    this.getForms();
  }

  /**
   * Goes to the previous page
   */
  previousPage() {
    if (this.actualPage > 1) {
      if (this.actualPage == this.mostrar[0]) {
        for (let index = 0; index < this.mostrar.length; index++) {
          this.mostrar[index] = this.mostrar[index] - 1;
        }
      }
      this.actualPage = this.actualPage - 1;
      this.getForms();
    }
  }

  /**
   * Goes to the next page
   */
  nextPage() {
    if (this.actualPage < this.totalPage.length) {
      /*  if (this.actualPage == this.mostrar[this.mostrar.length - 1]) {
        for (let index = 0; index < this.mostrar.length; index++) {
          this.mostrar[index] = this.mostrar[index] + 1;
        }
      } */
      this.actualPage = this.actualPage + 1;
      this.getForms();
    }
  }

  /**
   * Searches for the text entered in the input
   */
  search() {
    if (this.toSearch == "") {
      this.toSearch = undefined;
    }

    this._dynamicFormService
      .searchForm(this.toSearch, this.authService.getToken())
      .subscribe((response) => {
        this.forms = response.data.Items;
        this.loadDataTable(this.forms);
      });
  }

  /**
   * Clears all variables when creating a new form
   */
  clear() {
    this._dynamicFormService.formPreview = [];
    this._dynamicFormService.editForm = {};
    this._dynamicFormService.clearAll();
    this._dynamicFormService.logoFile = null;
    this._dynamicFormService.logoBase64 = null;
    this._dynamicFormService.isUpdatingForm = false;
    this._dynamicFormService.editForm = null;
    this._dynamicFormService.publicTitle = null;
    this._dynamicFormService.instructions = null;
    this._dynamicFormService.publicTitleCheck = true;
    this._dynamicFormService.instructionsCheck = true;
    this._dynamicFormService.isPrivate = false;
    this._dynamicFormService.themeName = "";
    this._dynamicFormService.jsonTheme = {
      nameTheme: "",
      parent: "",
      email: "",
      dataTheme: {
        backColor1: "",
        backColor2: "",
        boxColor: "",
        textHeader: "",
        textContent: "",
        playColor: "",
      },
    };
  }

  /**
   * It closes the "copied link" message
   */
  closeClipboard() {
    this.secondsClipboard--;

    if (this.secondsClipboard <= 0) {
      this.linkClipboard = false;
      clearInterval(this.timerClipboard);
    }
  }

  /**
   * It closes the "copied link" message on close button
   */
  buttonClose() {
    this.linkClipboard = false;
  }
  loadDataTable(data) {
    console.log(data);

    this.dataToTable = [];
    let answer = 0;
    let status = "active";
    var buttons;
    let colorActive;

    for (let index = 0; index < data.length; index++) {
      if (data[index].num_answer != undefined) {
        answer = data[index].num_answer;
      }
      if (data[index].statusForm) {
        status = "Active";
        colorActive = "#008000";
      } else {
        status = "Inactive";
        colorActive = "#ff0000";
      }

      if (data[index].form_ispublic == false) {
        buttons = [
          {
            tool: true,
            icon: "fas fa-link",
            action: "copyLink",
            title: "Copy link",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "far fa-calendar-check",
            action: "goToAnwersDashboard",
            title: "Answers",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "far fa-file-pdf",
            action: "downloadPdf",
            title: "Download PDF",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-table",
            action: "goToLeadsDashboard",
            title: "Leads Dashboard",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-pencil-alt",
            action: "editForm",
            title: "Edit form",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-trash-alt",
            action: "deleteForm",
            title: "Delete form",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-ban",
            action: "statusChange",
            title: status,
            color: colorActive,
          },
        ];
      } else {
        buttons = [
          {
            tool: true,
            icon: "fas fa-link",
            action: "copyLink",
            title: "Copy link",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "far fa-calendar-check",
            action: "goToAnwersDashboard",
            title: "Answers",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "far fa-file-pdf",
            action: "downloadPdf",
            title: "Download PDF",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-pencil-alt",
            action: "editForm",
            title: "Edit form",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-trash-alt",
            action: "deleteForm",
            title: "Delete form",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-ban",
            action: "statusChange",
            title: status,
            color: colorActive,
          },
        ];
      }
      if (data[index].tts == false) {
        console.log(data[index].tts);
        buttons = false;
      }
      if (this.mobile == false) {
        this.dataToTable.push({
          param1: data[index].title,
          param2: data[index].updated_at,
          param3: answer,
          param4: status,
          param5: buttons,
        });
      } else {
        buttons = [
          {
            tool: true,
            icon: "fas fa-link",
            action: "copyLink",
            title: "Copy link",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-table",
            action: "goToLeadsDashboard",
            title: "Leads Dashboard",
            color: "#6c757d",
          },
          {
            tool: true,
            icon: "fas fa-ban",
            action: "statusChange",
            title: status,
            color: colorActive,
          },
        ];
        console.log("data[index].answer", data[index]);

        this.dataToTable.push({
          param1: data[index].title,
          param2: data[index].num_answer,
          param3: buttons,
        });
        this.headersTable = [
          {
            text: "Name",
            sort: true,
          },
          {
            text: "Answers",
            sort: true,
          },
          {
            text: "Actions",
            sort: false,
          },
        ];
      }
    }
  }
  copyLink(token) {
    let ip = window.location.origin;
    let url = ip + "/user-forms/public-form/" + token;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  deleteForm(id) {
    swal({
      title: "Are you sure?",
      text: "Do you want to remove this form?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove!",
    }).then((result) => {
      if (result.value) {
        this._dynamicFormService
          .deleteJSON(id, this.authService.getToken())
          .subscribe((response) => {
            let index = 0;
            for (let i = 0; i < this.forms.length; i++) {
              if (this.forms[i]["id"] === id) index = i;
            }

            this.forms.splice(index, 1);
            this.loadDataTable(this.forms);
            this.getForms();
          });
      }
    });
  }
  onResize() {
    if (document.body.offsetWidth < 1105) {
      this.mobile = true;
    }
  }
}
