import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { TableCell, TableHeader } from "./models";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import swal from "sweetalert2";
import { form } from "src/app/user-forms/models/form.model";
import { TypeofPipe } from "../pipes/typeof";
import { from } from "rxjs";
@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnInit {
  @Input() dataToTable: Array<any>;
  @Input() headers: Array<any>;
  @Input() actionsColumns: Array<any>;

  @Output() actionTable: EventEmitter<any> = new EventEmitter();

  public bandera = false;
  public favorite: String;
  public theme: String;
  public backColor1: String;
  public backColor2: String;
  public boxColor: String;
  public textHeader: String;
  public textContent: String;
  public tableCell: Array<TableCell>;

  constructor(
    private _router: Router,
    private auth: AuthService,
    private _dynamicForm: DynamicFormService,
    private dynamicFormService: DynamicFormService,
    private authService: AuthService,
    private typePipe: TypeofPipe
  ) {
    this.nameOrder = false;
    this.favorite = "default";
  }

  public test2 = true;
  public nameOrder = false;
  public updateOrder = false;

  ngOnInit() {}

  orderTable(text) {
    console.log(text);
    console.log(this.nameOrder);

    if (this.nameOrder == false) {
      this.dataToTable.sort((a, b) => {
        let typeText = this.typePipe.transform(a["param" + text]);
        if (typeText == "string") {
          console.log("asdasd");
          return a["param" + text].toLocaleLowerCase() <
            b["param" + text].toLocaleLowerCase()
            ? 1
            : -1;
        }
        if (typeText == "number") {
          console.log("asdasd");
          return a["param" + text] < b["param" + text] ? 1 : -1;
        }
      });
      this.nameOrder = true;
    } else {
      if (this.nameOrder == true) {
        this.dataToTable.sort((a, b) => {
          let typeText = this.typePipe.transform(a["param" + text]);

          if (typeText == "string") {
            console.log("asdasd");
            return a["param" + text].toLocaleLowerCase() >
              b["param" + text].toLocaleLowerCase()
              ? 1
              : -1;
          }
          if (typeText == "number") {
            console.log("asdasd");
            return a["param" + text] > b["param" + text] ? 1 : -1;
          }
        });
        this.nameOrder = false;
      }
    }
  }

  actionToTrigger(index, action) {
    this.actionTable.emit({
      index,
      action
    });
  }
}
