import { Injectable } from "@angular/core";
declare const communicationPlatform: any;
@Injectable({
  providedIn: "root",
})
export class CommunicationPlatFormService {
  public cp: any;
  public timer: any;
  public fakeData: any;

  constructor() {
    this.cp = communicationPlatform();
  }
}
