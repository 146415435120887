import { element } from "protractor";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-form-stats",
  templateUrl: "./form-stats.component.html",
  styleUrls: ["./form-stats.component.scss"],
})
export class FormStatsComponent implements OnInit {
  public questions: any;
  constructor() {
    this.questions = [
      {
        type: "CheckBox",
        title: "Color favorito",
        Options: ["Option-1", "Option-2", "Option-3"],
      },
      {
        type: "Radio",
        title: "Carro favorito",
        Options: ["Option-1", "Option-2", "Option-3"],
      },
      {
        type: "Select",
        title: "Ciudad favorito",
        Options: ["Option-1", "Option-2", "Option-3"],
      },
    ];
  }

  ngOnInit() {}

  leerFechas(event) {
    console.log(event);
  }
}
