import { Component, OnInit } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle"; // import it to your component
import { StatsService } from "./stats/services/stats.service";
import swal from "sweetalert2";
import { AuthService } from "./auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConstants } from "./constants";
import { CommunicationPlatFormService } from "./services/communicationPlatFormService.service";
declare const window: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "WWTCForms";
  sessionExpiredCount: number;
  public comunicationPlat: boolean;
  public acceso = false;

  public currentTime: any;
  constructor(
    private bnIdle: BnNgIdleService,
    private statsService: StatsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private _router: Router,
    private communicationPlatForm: CommunicationPlatFormService
  ) {
    this.sessionExpiredCount = 0;
    this.comunicationPlat = AppConstants.CommunicationPlatForm;
  }

  ngOnInit() {
    if (this.comunicationPlat === true) {
      // var url_string = window.location.href;

      /* https://comm-platform-dev.worldwidetechconnections.com/forms/index.html?token=QWzPHYNEW2I7WkYgj9gA&uid=d9abf6db-459c-4ce6-b8bc-bcbcbc15cc90&appcode=Forms&email=romerjeancarlos@gmail.com */
      var url_string =
        "https://formsapp.worldwidetechconnections.com/index.html?token=h9iIEZdTC0qoMqkUMRhGTg&uid=25d8ce22-0eec-4005-81ec-249d176ea6bd&appcode=Forms&email=pravinder@worldwidetechconnections.com";

      var url = new URL(url_string);
      var token = url.searchParams.get("token");
      var uid = url.searchParams.get("uid");
      var appcode = url.searchParams.get("appcode");
      var email = url.searchParams.get("email");

      //comm-platform-dev.worldwidetechconnections.com/forms/index.html?token=nWtFyeIKEuXHu2I5eMVQ&uid=d9abf6db-459c-4ce6-b8bc-bcbcbc15cc90&appcode=Forms&email=romerjeancarlos@gmail.com
      // var cp = communicationPlatform();
      https: this.communicationPlatForm.fakeData = {
        email: email,
        pass: uid,
      };
      console.log(this.communicationPlatForm.fakeData);

      this.communicationPlatForm.cp.init(token, uid, appcode, email, (cb) => {
        this.acceso = cb.ok;

        if (cb.ok) {
          this.communicationPlatForm.cp.getTime(token, (response) => {
            this.currentTime = response;
          });
          this.communicationPlatForm.timer = setInterval(() => {
            this.communicationPlatForm.cp.getTime(token, (response) => {
              this.currentTime = response;
            });
          }, 10000);
        } else {
          var self = this;
          window.swal(
            {
              title: "",
              type: "info",
              text: cb.response.Message,
              confirmButtonColor: "#337AB7",
            },
            function () {
              self.communicationPlatForm.cp.disconnect();
              window.close();
            }
          );
        }
      });
    } else {
      this.bnIdle.startWatching(50000).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          console.log("session expired");
          console.log(this.statsService.sessionTime);
          console.log(this._router.url);

          if (
            this._router.url != "/" &&
            this._router.url != "/login" &&
            this._router.url != "/register"
          ) {
            this.sessionExpiredCount++;
            if (this.sessionExpiredCount < 2) {
              swal(
                "Session expired",
                "The session has expired, you will be redirected to login",
                "warning"
              ).then((result) => {
                this.statsService
                  .closeSession(this.authService.getToken())
                  .subscribe((data) => {
                    console.log(data);
                  });
                this.authService.logoutUser();
                this._router.navigate(["/login"]);
              });
            }
          }
        }
      });
    }
  }
}
