import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ɵɵresolveBody
} from "@angular/core";
import { formRow } from "../../user-forms/models/formRow.model";
import { formCol } from "../../user-forms/models/formCol.model";
import { colElement } from "../../user-forms/models/colElement.model";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { AuthService } from "src/app/auth/auth.service";
import { Router } from "@angular/router";
import swal from "sweetalert2";

declare var $: any;

@Component({
  selector: "app-preview-form",
  templateUrl: "./preview-form.component.html",
  styleUrls: ["./preview-form.component.scss"]
})
export class PreviewFormComponent implements OnInit {
  public active: boolean;
  public desktop: boolean;
  public json;
  public json2;
  public arrayRow: formRow[];
  public arrayCol: formCol[];
  public arrayElement: colElement[];
  public logoBase64: any;
  public titleForm: String;
  public instructions: String;
  public theme: String;
  public theme3: String;
  public color: string;
  public testColor: "red";
  public favorite: String;
  public backColor1: String;
  public backColor2: String;
  public boxColor: String;
  public textHeader: String;
  public textContent: String;
  public colorActual: any;
  public listThemes: any;
  public isThemeLoaded;
  public changed: boolean;
  public nameThemes: any[];
  public selectTheme: any;
  public user: any;
  public signature = false;
  public signatureResponsive = false;
  public publicTitleCheck;
  public instructionsCheck;
  public logoAlign;
  public nameFonts: any[];
  public selectedFont;

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  private signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 350,
    canvasHeight: 90
  };

  constructor(
    private _dynamicForm: DynamicFormService,
    private authService: AuthService,
    private _router: Router
  ) {
    this.color = "#ccc";
    this.testColor = "red";
    this.favorite = "default";

    this.changed = false;
    this.nameThemes = [];
    this.nameFonts = ["Segoe UI", "Cursive", "Arial", "Fantasy"];
    this.selectedFont = this.nameFonts[0];
    this.logoAlign = "center";

    this.selectTheme = "Default";
    this.theme3 = "theme3";
    this.getThemes();

    this.signature = this._dynamicForm.signature;

    if (!this.textContent) this.textContent = "#ffffff";
    if (document.body.offsetWidth < 475) {
      this.signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 200,
        canvasHeight: 90,
        penColor: this.hexToRgb(this.textContent)
      };
    } else {
      this.signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 350,
        canvasHeight: 90,
        penColor: this.hexToRgb(this.textContent)
      };
    }

    if (document.body.offsetWidth < 690) {
      this.signatureResponsive = true;
    }
  }

  ngOnInit() {
    this.arrayRow = [];

    this.user = this.authService.getCurrentUser();
    this.arrayCol = [];
    this.arrayElement = [];
    this.active = true;
    this.desktop = true;
    this.titleForm = this._dynamicForm.publicTitle;
    this.instructions = this._dynamicForm.instructions;

    this.loadArrayRow();

    if (this._dynamicForm.logoBase64) {
      this.logoBase64 = this._dynamicForm.logoBase64;
    }
    if (this._dynamicForm.selectedTheme != "") {
      this.selectTheme = this._dynamicForm.selectedTheme;
    }
    if (this._dynamicForm.themeName != "") {
      this.theme = this._dynamicForm.themeName;
    }

    this.publicTitleCheck = this._dynamicForm.publicTitleCheck;
    this.instructionsCheck = this._dynamicForm.instructionsCheck;
  }

  /**
   * After loading the view, load the colorPickers
   */
  ngAfterViewInit(): void {
    if (this._dynamicForm.jsonTheme.nameTheme != "") {
      $("#backColor1").colorpicker({
        color: this.backColor1,
        hideButton: true
      });
      $("#backColor2").colorpicker({
        color: this.backColor2,
        hideButton: true
      });
      $("#boxColor").colorpicker({
        color: this.boxColor,
        hideButton: true
      });
      $("#textHeader").colorpicker({
        color: this.textHeader,
        hideButton: true
      });
      $("#textContent").colorpicker({
        color: this.textContent,
        hideButton: true
      });
    }
  }
  /**
   * If a change is made, you are asked if you want to save the new changes
   */
  askSave() {
    if (this.changed == true) {
      swal({
        title: "Are you sure?",
        text:
          "The colors has been changed for the actual theme. Do you want to save this config?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00B96F",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          this._dynamicForm.jsonTheme.email = this.user.email;
          this._dynamicForm.jsonTheme.dataTheme.playColor = "#000000";
          this._dynamicForm.jsonTheme.nameTheme = this.theme + "Mod";
          this._dynamicForm.jsonTheme.parent = this.theme;
          this._dynamicForm.jsonTheme.dataTheme.backColor1 = this.backColor1;
          this._dynamicForm.jsonTheme.dataTheme.backColor2 = this.backColor2;
          this._dynamicForm.jsonTheme.dataTheme.boxColor = this.boxColor;
          this._dynamicForm.jsonTheme.dataTheme.textHeader = this.textHeader;
          this._dynamicForm.jsonTheme.dataTheme.textContent = this.textContent;
          this._dynamicForm.jsonTheme.dataTheme.selectedFont = this.selectedFont;
          this._dynamicForm.jsonTheme.dataTheme.logoAlign = this.logoAlign;

          this._router.navigate(["/new-form/step2"]);
        } else {
          this._router.navigate(["/new-form/step2"]);
        }
      });
    } else {
      this._router.navigate(["/new-form/step2"]);
    }
  }
  /**
   * Load the form created by the user and save the information in the models to be displayed
   */
  loadArrayRow() {
    for (let row = 0; row < this._dynamicForm.previewJson.length; row++) {
      this.arrayCol = [];
      for (
        let col = 0;
        col < this._dynamicForm.previewJson[row].formCol.length;
        col++
      ) {
        this.arrayElement = [];
        for (
          let element = 0;
          element <
          this._dynamicForm.previewJson[row].formCol[col].colElement.length;
          element++
        ) {
          this.arrayElement.push(
            this._dynamicForm.previewJson[row].formCol[col].colElement[element]
          );
        }
        this.arrayCol = [
          ...this.arrayCol,
          new formCol(
            col,
            row,
            this._dynamicForm.previewJson[row].formCol[col].percentage,
            this.arrayElement
          )
        ];
      }
      this.arrayRow = [...this.arrayRow, new formRow(row, 0, this.arrayCol)];
    }
  }
  /*  toggleValue(item) {
    ESTE METODO SE EJECUTA CUANDO UN CHECKBOX ES SELEECIONADO,
    NO ES NECESARIO EN EL PREVIEW 

    item.selected = !item.selected;
  } */
  /**
   * Change the size of the signature canvas when you switch to desktop or mobile
   */
  dispositiveDesktop() {
    this.desktop = true;

    if (document.body.offsetWidth < 475) {
      this.signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 200,
        canvasHeight: 90,
        penColor: this.hexToRgb(this.textContent)
      };
    } else {
      this.signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 350,
        canvasHeight: 90,
        penColor: this.hexToRgb(this.textContent)
      };
    }
  }
  /**
   * Activate mobile view flag
   */
  dispositiveMobil() {
    this.desktop = false;
    this.isThemeLoaded = true;
  }
  /**
   *
   * @param event
   * Gets the theme selected by the user
   */
  getTheme(event) {
    this.selectTheme = event.srcElement.value;
    this._dynamicForm.selectedTheme = event.srcElement.value;
    for (let index = 0; index < this.nameThemes.length; index++) {
      if (this.nameThemes[index].nameTheme == event.srcElement.value) {
        if (this.nameThemes[index].parent == "parent") {
          this.theme = this.nameThemes[index].nameTheme;
        } else {
          this.theme = this.nameThemes[index].parent;
        }

        this.backColor1 = this.nameThemes[
          index
        ].dataTheme.backColor1.toUpperCase();

        if (this.nameThemes[index].dataTheme.backColor2 == false) {
          this.backColor2 = this.nameThemes[
            index
          ].dataTheme.backColor1.toUpperCase();
        } else {
          this.backColor2 = this.nameThemes[
            index
          ].dataTheme.backColor2.toUpperCase();
        }
        if (this.nameThemes[index].dataTheme.boxColor == "transparent") {
          this.boxColor = "00000000";
        } else {
          this.boxColor = this.nameThemes[
            index
          ].dataTheme.boxColor.toUpperCase();
        }

        this.textHeader = this.nameThemes[
          index
        ].dataTheme.textHeader.toUpperCase();
        this.textContent = this.nameThemes[
          index
        ].dataTheme.textContent.toUpperCase();
      }
    }
    this._dynamicForm.themeName = this.theme;

    this.changed = true;
  }
  /**
   *
   * @param id
   * Initialize the different colorPickers for each of the elements
   */
  showColorPicker(id) {
    $("#" + id).on(
      "change.color",
      function(event, color) {
        if (color != undefined) {
          this.colorActual = color;

          switch (id) {
            case "backColor1":
              this.backColor1 = color.toUpperCase();
              this.changed = true;
              break;

            case "backColor2":
              this.backColor2 = color.toUpperCase();
              this.changed = true;
              break;
            case "boxColor":
              this.boxColor = color.toUpperCase();
              this.changed = true;
              break;
            case "textHeader":
              this.textHeader = color.toUpperCase();
              this.changed = true;
              break;
            case "textContent":
              this.textContent = color.toUpperCase();
              this.changed = true;
              break;
          }
        }
      }.bind(this)
    );
  }

  /**
   * Gets the available themes and loads them into the theme selection button
   */
  getThemes() {
    if (this._dynamicForm.jsonTheme.nameTheme === "") {
      this.isThemeLoaded = false;
      this._dynamicForm.arrayTheme = [];
      this._dynamicForm.getThemes(this.authService.getToken()).subscribe(
        response => {
          /* this.favorite = response.favorite */

          for (let index = 0; index < response.data.length; index++) {
            this._dynamicForm.arrayTheme.push(response.data[index]);
            if (response.data[index].nameTheme == this.favorite) {
              if (this._dynamicForm.themeName == "") {
                if (response.data[index].parent == "parent") {
                  this.theme = response.data[index].nameTheme;
                } else {
                  this.theme = response.data[index].parent;
                }
              }

              this.backColor1 = response.data[
                index
              ].dataTheme.backColor1.toUpperCase();
              if (response.data[index].dataTheme.backColor2 == false) {
                this.backColor2 = this.backColor1.toUpperCase();
              } else {
                this.backColor2 = response.data[
                  index
                ].dataTheme.backColor2.toUpperCase();
              }
              if (response.data[index].dataTheme.boxColor == "transparent") {
                this.boxColor = "#FFFFFF";
              } else {
                this.boxColor = response.data[
                  index
                ].dataTheme.boxColor.toUpperCase();
              }
              this.textHeader = response.data[
                index
              ].dataTheme.textHeader.toUpperCase();
              this.textContent = response.data[
                index
              ].dataTheme.textContent.toUpperCase();
            }
          }
          this.nameThemes = JSON.parse(
            JSON.stringify(this._dynamicForm.arrayTheme)
          );
          for (let index = 0; index < this.nameThemes.length; index++) {
            if (this.nameThemes[index].nameTheme == "default") {
              let aux = this.nameThemes[0];
              this.nameThemes[0] = this.nameThemes[index];
              this.nameThemes[index] = aux;
            }
          }
        },
        error => {
          this.backColor1 = "#000000";
          this.backColor2 = "#000000";
          this.boxColor = "#0000ffff";
          this.textHeader = "#000000";
          this.textContent = "#000000";
        },
        () => {
          this.isThemeLoaded = true;

          $("#backColor1").colorpicker({
            color: this.backColor1
          });
          $("#backColor2").colorpicker({
            color: this.backColor1
          });
          $("#boxColor").colorpicker({
            color: this.backColor1
          });
          $("#textHeader").colorpicker({
            color: this.backColor1
          });
          $("#textContent").colorpicker({
            color: this.backColor1
          });

          if (
            this._dynamicForm.editForm &&
            Object.keys(this._dynamicForm.editForm).length > 0
          ) {
            this.isEdit();
          }
        }
      );
    } else {
      if (
        this._dynamicForm.editForm &&
        Object.keys(this._dynamicForm.editForm).length > 0
      ) {
        console.log(this._dynamicForm.editForm);

        this.isEdit();
      }
      this._dynamicForm.arrayTheme = [];
      this._dynamicForm.getThemes(this.authService.getToken()).subscribe(
        response => {
          for (let index = 0; index < response.data.length; index++) {
            this._dynamicForm.arrayTheme.push(response.data[index]);
          }
        },
        error => {},
        () => {
          this.nameThemes = JSON.parse(
            JSON.stringify(this._dynamicForm.arrayTheme)
          );
        }
      );
      console.log("this._dynamicForm.jsonTheme", this._dynamicForm.jsonTheme);

      this.theme = this._dynamicForm.jsonTheme.parent;
      this.backColor1 = this._dynamicForm.jsonTheme.dataTheme.backColor1.toUpperCase();
      this.backColor2 = this._dynamicForm.jsonTheme.dataTheme.backColor2.toUpperCase();
      this.boxColor = this._dynamicForm.jsonTheme.dataTheme.boxColor.toUpperCase();
      this.textHeader = this._dynamicForm.jsonTheme.dataTheme.textHeader.toUpperCase();
      this.textContent = this._dynamicForm.jsonTheme.dataTheme.textContent.toUpperCase();
      this.logoAlign = this._dynamicForm.jsonTheme.dataTheme.logoAlign;
      this.selectedFont = this._dynamicForm.jsonTheme.dataTheme.selectedFont;

      this.isThemeLoaded = true;
    }
  }

  /**
   * clear canvas signature
   */
  clearSignature() {
    this.signaturePad.clear();
  }
  /**
   * Switches from hexadecimal to RGB
   */
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return (
      "rgb(" +
      parseInt(result[1], 16) +
      "," +
      parseInt(result[2], 16) +
      "," +
      parseInt(result[3], 16) +
      ")"
    );
  }

  /**
   * If you are editing the form, load all the information about the topic and configuration of the form
   */
  isEdit() {
    if (this._dynamicForm.editForm.theme.parent) {
      if (this._dynamicForm.editForm.theme.parent == "parent") {
        this.theme = this._dynamicForm.editForm.theme.nameTheme;
        this.selectTheme = this._dynamicForm.editForm.theme.nameTheme;
      } else {
        this.theme = this._dynamicForm.editForm.theme.parent;
        this.selectTheme = this._dynamicForm.editForm.theme.parent;
      }
      /* if(){
          this.logoAlign
          this.selectedFont
        } */
      if (this._dynamicForm.editForm.theme.dataTheme.logoAlign) {
        this._dynamicForm.jsonTheme.dataTheme.logoAlign = this._dynamicForm.editForm.theme.dataTheme.logoAlign;
      }
      if (this._dynamicForm.editForm.theme.dataTheme.selectedFont) {
        this._dynamicForm.jsonTheme.dataTheme.selectedFont = this._dynamicForm.editForm.theme.dataTheme.selectedFont;
      }

      this.backColor1 = this._dynamicForm.editForm.theme.dataTheme.backColor1.toUpperCase();
      if (this._dynamicForm.editForm.theme.dataTheme.backColor2 == false) {
        this.backColor2 = this.backColor1.toUpperCase();
      } else {
        this.backColor2 = this._dynamicForm.editForm.theme.dataTheme.backColor2.toUpperCase();
      }
      if (
        this._dynamicForm.editForm.theme.dataTheme.boxColor == "transparent"
      ) {
        this.boxColor = "#FFFFFF";
      } else {
        this.boxColor = this._dynamicForm.editForm.theme.dataTheme.boxColor.toUpperCase();
      }
      this.textHeader = this._dynamicForm.editForm.theme.dataTheme.textHeader.toUpperCase();
      this.textContent = this._dynamicForm.editForm.theme.dataTheme.textContent.toUpperCase();
    } else {
      if (this._dynamicForm.editForm.theme) {
        this._dynamicForm.jsonTheme.nameTheme = this._dynamicForm.editForm.theme;
      } else {
        this._dynamicForm.jsonTheme.nameTheme = "default";
      }
    }
  }

  /**
   *
   * @param event
   * Render the canvas of the signature field
   */
  onResize(event) {
    if (document.body.offsetWidth < 672) {
      this.signatureResponsive = true;
    } else {
      this.signatureResponsive = false;
    }
  }

  align(param) {
    this.logoAlign = param;
    this._dynamicForm.jsonTheme.dataTheme.logoAlign = this.logoAlign;
    this.changed = true;
  }

  changeFont(e) {
    this.selectedFont = e;
    this._dynamicForm.jsonTheme.dataTheme.selectedFont = this.selectedFont;
    this.changed = true;
  }
}
