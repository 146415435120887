import { Component, OnInit } from "@angular/core";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-new-form-confirmation",
  templateUrl: "./new-form-confirmation.component.html",
  styleUrls: ["./new-form-confirmation.component.scss"]
})
export class NewFormConfirmationComponent implements OnInit {
  publicURL: string;
  baseURL: string;

  constructor(
    private _dynamicForm: DynamicFormService,
    private router: Router
  ) {
    let ip = window.location.origin;
    this.publicURL = ip + this._dynamicForm.publicURL;
    // this.publicURL = ip + "/formswwtc" + this._dynamicForm.publicURL;
  }
  //this.router.navigate([this._dynamicForm.publicURL]);

  ngOnInit() {}

  goPublicURL() {}

  printForm() {
    this.router
      .navigate([this._dynamicForm.publicURL])
      .finally(() => (this._dynamicForm.goPrint = true));
  }
}
