import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { StatsRoutingModule } from "./stats-routing.module";
import { StatsComponent } from "./stats.component";
import { FormStatsComponent } from "./form-stats/form-stats.component";
import { QuestionComponent } from "./question/question.component";
import { TopLanguageComponent } from "./top-language/top-language.component";
import { DateSelectorComponent } from "./date-selector/date-selector.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { StatusCardComponent } from "./status-card/status-card.component";
import { BnNgIdleService } from "bn-ng-idle";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbdDatepickerRangePopup } from "./datepicker/datepicker.component";

@NgModule({
  declarations: [
    StatsComponent,
    FormStatsComponent,
    QuestionComponent,
    AdminDashboardComponent,
    StatusCardComponent,
    TopLanguageComponent,
    DateSelectorComponent,
    NgbdDatepickerRangePopup,
  ],
  imports: [CommonModule, StatsRoutingModule, SharedModule, NgbModule],
  providers: [BnNgIdleService, DatePipe],
  exports: [NgbdDatepickerRangePopup],
  bootstrap: [NgbdDatepickerRangePopup],
})
export class StatsModule {}
