import { colElement } from "./../models/colElement.model";
import { formCol } from "./../models/formCol.model";
import { Component, OnInit, ViewChild, SimpleChanges } from "@angular/core";
import { formRow } from "../../user-forms/models/formRow.model";

import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { ApiService } from "src/app/services/api.service";
import { interval } from "rxjs";
import { User } from "../models/user";
import swal from "sweetalert2";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { DecodeHtmlStringPipe } from "src/app/shared/pipes/decode-html-string.pipe";

declare var MediaRecorder: any;

@Component({
  selector: "app-public-form",
  templateUrl: "./public-form.component.html",
  styleUrls: ["./public-form.component.scss"],
})
export class PublicFormComponent implements OnInit {
  public active: boolean;
  public desktop: boolean;
  public json;
  public json2;
  public formLanguages: Array<any>;
  public formLanguagesTest: Array<any>;
  public formLanguagesCode: Array<any>;
  public formLanguagesDropDown: Array<any>;
  public formLanguagesDropDownAll: Array<any>;
  public formFlagsLanguages: Array<any>;
  public formFlagsLanguagesDropDown: Array<any>;
  public formFlagsLanguagesDropDownAll: Array<any>;
  public formToSend: any;
  public badToSend: boolean;
  public arrayRow: formRow[];
  public arrayCol: formCol[];
  public arrayElement: colElement[];
  public arrayResponse;
  public titleForm;
  public instructions;
  public urlToken;
  public identity;
  public signature;
  public selectedLanguage;
  public isFormHotel;
  public tax: boolean;
  public cdcForm: boolean;
  public taxRow: boolean;
  public favoriteLang: any[];
  public isFavorite: boolean;
  public isLoading = true;
  public isArabic = false;
  public logoBase64;
  public ttsToken;
  public message;
  public theme;
  public backColor1: String;
  public backColor2: String;
  public boxColor: String;
  public textHeader: String;
  public textContent: String;
  public signatureResponsive = false;
  public stringLanguageSearched: Array<any>;
  public clearFilterAll;
  public clearFilterDrop;
  public mediaRecorder: any;
  public pctVal: any;
  public responseCompleted: boolean;
  public responseMsg: string;
  public changueFormCode: string;
  public languageIsDetected: boolean;
  public tryDetectLang: boolean;
  public styleTest: any;
  public jsonTheme: any;
  public isBoxColorTransparent: boolean;
  public formId: any;
  public publicTitleCheck: boolean;
  public instructionsCheck: boolean;
  public pattern;
  public logoAlign;
  public selectedFont;
  public isPrivate: boolean;
  public user: User;
  public countryCode: any;
  public isSubmitting: boolean;
  public signatureWidth: number;
  public arrayLang: any;

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  private signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 350,
    canvasHeight: 90,
  };

  constructor(
    private _dynamicForm: DynamicFormService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private _router: Router,
    private _http: ApiService,
    private decodeHtmlString: DecodeHtmlStringPipe
  ) {
    this.pattern = [
      "Interviewer information",
      "Basic Information",
      "Symptoms, clinical course, past medical history and social history",
    ];
    this.cdcForm = false;
    this.selectedLanguage = "en";
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];
    this.arrayResponse = [];
    this.formLanguagesCode = [];
    this.badToSend = false;
    this.formLanguages = [];
    this.formLanguagesTest = [];
    this.formLanguagesDropDown = [];
    this.formLanguagesDropDownAll = [];
    this.formFlagsLanguages = [];
    this.formFlagsLanguagesDropDown = [];
    this.formFlagsLanguagesDropDownAll = [];
    this.stringLanguageSearched = [];
    this.favoriteLang = [];
    this.clearFilterAll = "";
    this.clearFilterDrop = "";
    this.active = false;
    this.isFormHotel = false;
    this.tax = false;
    this.taxRow = false;
    this.isFavorite = true;
    this.logoBase64 = null;
    this.theme = "default";
    this.titleForm = "";
    this.selectedFont = "Segoe UI";
    this.logoAlign = "center";
    this.isPrivate = false;
    this.user = new User("", "", "", "", "");
    this.isSubmitting = false;

    this.pctVal = 1;
    this.responseCompleted = false;
    this.languageIsDetected = false;
    this.tryDetectLang = false;
    this.isBoxColorTransparent = false;
    this.route.paramMap.subscribe((params) => {
      this.urlToken = params.get("token");
      this._dynamicForm.shareScreenToken = this.urlToken;

      this._dynamicForm.getJSONbyToken(this.urlToken).subscribe(
        async (response) => {
          console.log(response);

          if (response.message != "Temporarily inactive form") {
            if (!response.form.form_ispublic) {
              this.isPrivate = true;
            }

            if (this.authService.getCurrentUser() && response.form.email_user) {
              if (
                this.authService.getCurrentUser().email ===
                response.form.email_user
              ) {
                this.isPrivate = false;
              }
            }
          } else {
            this.active = true;
          }
          /* formularios anteriores al cambio, todos privados */

          /* formularios anteriores al cambio, todos publicos */
          // if (response.form.form_ispublic === undefined) {
          //   this.isPrivate = false;
          // }

          // if (response.form.form_ispublic === false) {
          //   this.isPrivate = true;
          // }

          if (response.message != "Temporarily inactive form") {
            this.formId = response.form.id;
            if (response.form.theme) {
              if (response.form.theme.parent) {
                this.jsonTheme = response.form.theme;
                if (response.form.theme.parent == "parent") {
                  this.theme = response.form.theme.nameTheme;
                } else {
                  this.theme = response.form.theme.parent;
                }
                if (response.form.theme.dataTheme.logoAlign) {
                  this.logoAlign = response.form.theme.dataTheme.logoAlign;
                }
                if (response.form.theme.dataTheme.selectedFont) {
                  this.selectedFont =
                    response.form.theme.dataTheme.selectedFont;
                }
                this.backColor1 = response.form.theme.dataTheme.backColor1;
                if (response.form.theme.dataTheme.backColor2 == false) {
                  this.backColor2 = response.form.theme.dataTheme.backColor1;
                } else {
                  this.backColor2 = response.form.theme.dataTheme.backColor2;
                }
                if (response.form.theme.dataTheme.boxColor == "transparent") {
                  if (this.theme != "default") {
                    this.isBoxColorTransparent = true;
                    this.boxColor = "#00000000";
                  }
                } else {
                  this.isBoxColorTransparent = false;
                  this.boxColor = response.form.theme.dataTheme.boxColor;
                }

                if (response.form.theme.dataTheme.boxColor == "#00000000") {
                  if (this.theme != "default") {
                    this.isBoxColorTransparent = true;
                  }
                } else {
                  this.isBoxColorTransparent = false;
                }

                this.textHeader = response.form.theme.dataTheme.textHeader;
                this.textContent = response.form.theme.dataTheme.textContent;
              } else {
                if (response.form.theme) this.theme = response.form.theme;
                else this.theme = "default";
              }
            }
          } else {
            this.active = true;
          }

          if (response.code && (response.code = 404)) {
            this.message = response.message;
            this.active = false;
          } else {
            this.logoBase64 = response.form["logo_url"];

            if (this.logoBase64 === "emphy") {
              this.logoBase64 = null;
            }

            for (
              let index = 0;
              index < response.form.languages.length;
              index++
            ) {
              this.formLanguagesCode[index] =
                response.form.languages[index].code;
            }
            this.formLanguagesTest = response.form.languages;

            console.log(this.formLanguagesTest);

            for (
              let index = 0;
              index < response.form.languages.length;
              index++
            ) {
              this.formLanguages[index] = response.form.languages[index].code;
              this.formFlagsLanguages[index] =
                response.form.languages[index].flag;
            }

            if (response.form.languages.length >= 5) {
              let cont = 0;
              for (let index = 4; index < this.formLanguages.length; index++) {
                this.formLanguagesDropDown[cont] = this.formLanguages[index];
                this.formFlagsLanguagesDropDown[cont] = this.formFlagsLanguages[
                  index
                ];
                cont++;
              }
            }

            //boton para todos los lenguajes
            let cont = 0;
            for (let index = 0; index < this.formLanguages.length; index++) {
              this.formLanguagesDropDownAll[cont] = this.formLanguages[index];
              this.formFlagsLanguagesDropDownAll[
                cont
              ] = this.formFlagsLanguages[index];
              cont++;
            }

            this.titleForm = response.form.publicTitle;

            if (this.titleForm === "Hotel Registration Card") {
              this.isFormHotel = true;
            }

            if (this.titleForm == "W2G Tax Form") {
              this.tax = true;
              this.taxRow = true;
            }

            if (response.form.instructions === "emphy") {
              this.instructions = "";
            } else {
              this.instructions = response.form.instructions;
            }

            this.publicTitleCheck = response.form.publicTitleCheck;
            this.instructionsCheck = response.form.instructionsCheck;

            if (response.form.publicTitleCheck === undefined) {
              this.publicTitleCheck = true;
            }
            if (response.form.instructionsCheck === undefined) {
              this.instructionsCheck = true;
            }

            this.arrayResponse = response.form.formRows;

            this.signature = response.form.signature;
            this.loadArrayRow();

            let array = this.formLanguagesCode;
            array.unshift("en");

            this.arrayLang = this.formLanguagesCode;

            this.recursiveLanguages(0, array.length);
            this.active = true;
          }
        },
        (error) => {},
        () => {
          if (!this.textContent) this.textContent = "#ffffff";

          // var inputValue = (<HTMLInputElement>(
          //   document.getElementById("mainContent")
          // )).value;

          this.signatureWidth = document.body.scrollWidth - 157;

          if (document.body.offsetWidth < 690) {
            this.signatureResponsive = true;
            this.signaturePadOptions = {
              minWidth: 2,
              canvasWidth: this.signatureWidth,
              canvasHeight: 90,
              penColor: this.hexToRgb(this.textContent),
            };
          } else {
            this.signaturePadOptions = {
              minWidth: 2,
              canvasWidth: 350,
              canvasHeight: 90,
              penColor: this.hexToRgb(this.textContent),
            };
          }
          this.styleTest = {
            color: this.textHeader,
          };
        }
      );
      this.isLoading = false;
    });

    this._http.getTokenTTS().subscribe((response: any) => {
      this.ttsToken = response.token;
    });
  }

  ngOnInit() {
    this.identity = false;
    this.countryCode = "1";

    this.desktop = true;
    this.titleForm = this._dynamicForm.formName;

    if (this._dynamicForm.instructions == "emphy") {
      this.instructions = "";
    } else {
      this.instructions = this._dynamicForm.instructions;
    }
  }

  /**
   * Loads the data of the form to be displayed in the view
   */
  loadArrayRow() {
    if (this.badToSend === false) {
      this.formToSend = this.arrayResponse;
      this.badToSend = true;
    }

    for (let row = 0; row < this.arrayResponse.length; row++) {
      this.arrayCol = [];
      for (let col = 0; col < this.arrayResponse[row].formCol.length; col++) {
        this.arrayElement = [];
        for (
          let element = 0;
          element < this.arrayResponse[row].formCol[col].colElement.length;
          element++
        ) {
          if (
            this.arrayResponse[row].formCol[col].colElement[element]
              .elementTitle == "emphy"
          ) {
            this.arrayResponse[row].formCol[col].colElement[
              element
            ].elementTitle = "";
          }
          if (
            this.arrayResponse[row].formCol[col].colElement[element]
              .placeholder == "emphy"
          ) {
            this.arrayResponse[row].formCol[col].colElement[
              element
            ].placeholder = "";
          }

          this.arrayElement.push(
            this.arrayResponse[row].formCol[col].colElement[element]
          );
          this.arrayElement[element].valor = "";
        }
        this.arrayCol = [
          ...this.arrayCol,
          new formCol(
            col,
            row,
            this.arrayResponse[row].formCol[col].percentage,
            this.arrayElement
          ),
        ];
      }
      this.arrayRow = [...this.arrayRow, new formRow(row, 0, this.arrayCol)];
    }
  }

  /**
   * Gets the json of the form by the code (parameter number)
   */
  getCodeLanguage(number) {
    console.log(number);

    if (this.stringLanguageSearched.length > 0) {
      if (this.stringLanguageSearched[0].searchedAll == false) {
        this.selectedLanguage = this.formLanguagesCode[
          this.stringLanguageSearched[number - 5].indexCode
        ];
        console.log("stringLanguageSearched", this.stringLanguageSearched);
      } else {
        console.log("stringLanguageSearched", this.stringLanguageSearched);

        this.selectedLanguage = this.formLanguagesCode[
          this.stringLanguageSearched[number - 1].indexCode + 1
        ];
      }
    } else {
      this.selectedLanguage = this.formLanguagesCode[number];
    }
    console.log("selectedLanguage", this.selectedLanguage);

    if (this.selectedLanguage.includes("arabic")) {
      this.isArabic = true;
    } else {
      this.isArabic = false;
    }
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];
    this._dynamicForm
      .getJSONbyTokenLanguage(this.urlToken, this.selectedLanguage)
      .subscribe((response) => {
        if (response.form.instructions === "emphy") {
          this.instructions = "";
        } else {
          console.log(response.form.form_languages.form);

          this.instructions = response.form.form_languages.form.instructions;
        }
        this.titleForm = response.form.form_languages.form.publicTitle;

        this.arrayResponse = response.form.form_languages.form.formRows;
        this.loadArrayRow();
      });
  }

  /**
   * Toggles value of the parameter given
   */
  toggleValue(item) {
    item.selected = !item.selected;
  }

  /**
   * Actives the desktop flag
   */
  dispositiveDesktop() {
    this.desktop = true;
  }

  /**
   * Actives the mobile flag
   */
  dispositiveMobil() {
    this.desktop = false;
  }

  /**
   * Text to Speech for the element
   */
  playLabel(ele) {
    let lang = "en";

    if (this.selectedLanguage && this.selectedLanguage != "") {
      lang = this.selectedLanguage;
    }

    let text = "";
    if (ele.elementTitle) {
      text = ele.elementTitle;
    } else if (ele.placeholder) {
      text = ele.placeholder;
    } else if (ele.label) {
      text = ele.label;
      this.playText(text);
      return;
    }

    if (this.isFavorite) {
      let audio = new Audio();
      audio.src = ele.elementTitle_url;
      if (ele.elementType === "paragraph") audio.src = ele.placeholder_url;
      audio.load();
      audio.play();
    } else {
      this._http.getTokenTTS().subscribe((response: any) => {
        let token = response.token;

        this._http.getTTS(token, lang, text).subscribe((response: any) => {
          let URL = window.URL;
          let blob = new Blob([response], { type: "audio/mp3" });
          let url = URL.createObjectURL(blob);

          let audio = new Audio();
          audio.src = url;
          audio.load();
          audio.play();
        });
      });
    }
  }

  /**
   * Text to Speech the text in the input
   */
  playField(id) {
    var inputValue = (<HTMLInputElement>document.getElementById(id)).value;

    let lang = "en";

    if (this.selectedLanguage && this.selectedLanguage != "") {
      lang = this.selectedLanguage;
    }

    this.playText(inputValue);
  }

  /**
   * Call the service TTS for the given parameter
   */
  playText(text) {
    let lang = "en";
    lang = "english-united-states";

    if (this.selectedLanguage && this.selectedLanguage != "") {
      lang = this.selectedLanguage;
    }

    // this._http.getTTS(this.ttsToken, lang, text).subscribe((response: any) => {
    //   let URL = window.URL;
    //   let blob = new Blob([response], { type: "audio/mp3" });
    //   let url = URL.createObjectURL(blob);

    //   let audio = new Audio();
    //   audio.src = url;
    //   audio.load();
    //   audio.play();
    // });

    if (this.selectedLanguage === "en") {
      this.selectedLanguage = "english-united-states";
    }

    this._http
      .getTTSwwtc(this.selectedLanguage, text)
      .subscribe((response: any) => {
        var snd = new Audio("data:audio/wav;base64," + response.audio);
        snd.play();
      });
  }

  /**
   * Sets english as the current language
   */
  originalLanguage() {
    this.isArabic = false;
    this.selectedLanguage = this.formLanguagesCode[0];
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];

    this.titleForm = this.favoriteLang[0].publicTitle;
    if (this.favoriteLang[0].instructions === "emphy") {
      this.instructions = "";
    } else {
      this.instructions = this.favoriteLang[0].instructions;
    }

    this.arrayResponse = this.favoriteLang[0].rows;

    this.loadArrayRow();
  }

  /**
   * Cleans up the signature
   */
  clearSignature() {
    this.signaturePad.clear();
  }

  /**
   * Changes case to lower
   */
  firstMayus(string) {
    let cadena = string.toLowerCase();
    return cadena;
  }

  /**
   * Get all the jsons per language in the current form
   */
  async recursiveLanguages(element, n) {
    if (element < n) {
      this._dynamicForm
        .getJSONbyTokenLanguage(this.urlToken, this.formLanguagesCode[element])
        .subscribe((response) => {
          this.favoriteLang.push({
            publicTitle: response.form.publicTitle,
            instructions: response.form.instructions,
            rows: response.form.formRows,
          });

          this.recursiveLanguages(element + 1, n);
        });
    }
  }

  /**
   * Switch signature responsive flag on resize
   */
  onResize(event) {
    if (document.body.offsetWidth < 672) {
      this.signatureResponsive = true;
    } else {
      this.signatureResponsive = false;
    }
  }

  /**
   * Converts hex to rgb
   */
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return (
      "rgb(" +
      parseInt(result[1], 16) +
      "," +
      parseInt(result[2], 16) +
      "," +
      parseInt(result[3], 16) +
      ")"
    );
  }

  /**
   * Search the input text on available languages drop down on enter
   */
  search(name) {
    this.stringLanguageSearched = [];
    let string = name.srcElement.value.toLowerCase();

    for (let index = 0; index < this.formLanguagesDropDown.length; index++) {
      if (this.formLanguagesDropDown[index].search(string) != -1) {
        this.stringLanguageSearched.push({
          name: this.formLanguagesDropDown[index],
          flag: this.formFlagsLanguagesDropDown[index],
          indexCode: index + 5,
          searchedAll: false,
        });
      } else {
      }
    }
    if (
      this.stringLanguageSearched.length == this.formLanguagesDropDown.length
    ) {
      this.stringLanguageSearched = [];
    }
  }

  /**
   * Search the input text on available languages drop down (all) on enter
   */
  searchAll(name) {
    this.stringLanguageSearched = [];
    let string = name.srcElement.value.toLowerCase();

    for (let index = 0; index < this.formLanguagesDropDownAll.length; index++) {
      if (this.formLanguagesDropDownAll[index].search(string) != -1) {
        this.stringLanguageSearched.push({
          name: this.formLanguagesDropDownAll[index],
          flag: this.formFlagsLanguagesDropDownAll[index],
          indexCode: index,
          searchedAll: true,
        });
      } else {
      }
    }

    if (
      this.stringLanguageSearched.length == this.formLanguagesDropDownAll.length
    ) {
      this.stringLanguageSearched = [];
    }
  }

  /**
   * Filter dynamically the input text on available languages drop down
   */
  filterDrop() {
    this.stringLanguageSearched = [];
    let string = this.clearFilterDrop;
    console.log("stringLanguageSearched", this.stringLanguageSearched);

    if (string == "") {
    }

    for (let index = 0; index < this.formLanguagesDropDown.length; index++) {
      if (this.formLanguagesDropDown[index].search(string) != -1) {
        this.stringLanguageSearched.push({
          name: this.formLanguagesDropDown[index],
          flag: this.formFlagsLanguagesDropDown[index],
          indexCode: index + 5,
          searchedAll: false,
        });
      } else {
      }
    }
    console.log("stringLanguageSearched", this.stringLanguageSearched);

    if (
      this.stringLanguageSearched.length == this.formLanguagesDropDown.length
    ) {
      this.stringLanguageSearched = [];
    }
  }

  /**
   * Filter dynamically the input text on available languages drop down (all)
   */
  filterAll() {
    this.stringLanguageSearched = [];
    let string = this.clearFilterAll;

    if (string == "") {
    }

    for (let index = 0; index < this.formLanguagesDropDownAll.length; index++) {
      if (this.formLanguagesDropDownAll[index].search(string) != -1) {
        this.stringLanguageSearched.push({
          name: this.formLanguagesDropDownAll[index],
          flag: this.formFlagsLanguagesDropDownAll[index],
          indexCode: index,
          searchedAll: true,
        });
      } else {
      }
    }
    if (
      this.stringLanguageSearched.length == this.formLanguagesDropDownAll.length
    ) {
      this.stringLanguageSearched = [];
    }
  }

  /**
   * Check if the language received from audio detector is available in the current form
   */
  changueForm(code) {
    this.responseCompleted = true;
    this.changueFormCode = code;
    this.tryDetectLang = false;

    if (code === "*" || code === "error") {
      this.languageIsDetected = false;
      this.responseMsg =
        "We have not detected a source language, please try again";
    }

    for (let index = 0; index < this.formLanguagesCode.length; index++) {
      if (code == this.formLanguagesCode[index]) {
        if (index === 0) {
          this.responseMsg = "We have detected english as your language";
        }

        if (index > 0) {
          this.responseMsg =
            "We have detected " +
            this.formLanguages[index - 1] +
            " as your language";
        }
        this.selectedLanguage = code;
        this.languageIsDetected = true;
        break;
      } else {
        this.responseMsg =
          "The language detected (" + code + ") is not available in this form";
      }
    }
  }

  /**
   * Confirm detected language by audio detector
   */
  selectLang() {
    let code = this.changueFormCode;
    if (this.selectedLanguage === "ar") {
      this.isArabic = true;
    } else {
      this.isArabic = false;
    }
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];
    this._dynamicForm
      .getJSONbyTokenLanguage(this.urlToken, code)
      .subscribe((response) => {
        if (response.form.instructions === "emphy") {
          this.instructions = "";
        } else {
          this.instructions =
            response.form.form_languages[0][0].form.instructions;
        }
        this.titleForm = response.form.form_languages[0][0].form.publicTitle;

        this.arrayResponse = response.form.form_languages[0][0].form.formRows;
        this.loadArrayRow();
      });
  }

  /**
   * Try again the language detector
   */
  sendTry() {
    this.tryDetectLang = true;
    this.responseMsg = " ";
  }

  /**
   * Clears the feedback message
   */
  clearMsg() {
    this.responseMsg = " ";
  }

  /**
   * Submits the form
   */
  onSubmit(publicForm) {
    this.saveAnswerEnglish();

    this.isSubmitting = true;
    if (this.signature) {
      var file = this.dataURLtoFile(this.signaturePad.toDataURL(), "file.png");
      this._dynamicForm.saveSignature(file).subscribe(
        (response) => {
          console.log(response.signature_url);
          let jsonAnswer = {
            formRows: this.formToSend,
            instructions: this.instructions,
            language: this.selectedLanguage,
            logo_url: this.logoBase64,
            publicTitle: this.titleForm,
            signature: this.signature,
            theme: this.jsonTheme,
            id: this.formId,
            signature_url: response.signature_url,
          };

          console.log(jsonAnswer);

          this._dynamicForm.setAnswer(JSON.stringify(jsonAnswer)).subscribe(
            (response) => {
              this.isSubmitting = false;
              console.log(response);
              swal("Great! ", "Successfully submitted", "success");
            },
            (error) => {
              this.isSubmitting = false;
              swal(
                "Ooops",
                "An error occurred while submitting the form",
                "error"
              );
            }
          );
        },
        (error) => {
          this.isSubmitting = false;
          swal("Ooops", "An error occurred while submitting the form", "error");
        }
      );
    } else {
      let jsonAnswer = {
        formRows: this.formToSend,
        instructions: this.instructions,
        language: this.selectedLanguage,
        logo_url: this.logoBase64,
        publicTitle: this.titleForm,
        signature: this.signature,
        theme: this.jsonTheme,
        id: this.formId,
      };

      console.log(jsonAnswer);

      this._dynamicForm.setAnswer(JSON.stringify(jsonAnswer)).subscribe(
        (response) => {
          this.isSubmitting = false;

          swal("Great! ", "Successfully submitted", "success").then(
            (result) => {
              if (result.value) {
                this.authService.getCurrentUser();

                if (this.authService.getCurrentUser()) {
                  this._router.navigate(["/user-forms/dashboard"]);
                } else {
                  this._router.navigate(["/login"]);
                }
              }
            }
          );
        },
        (error) => {
          console.log(error);
          this.isSubmitting = false;
          swal("Ooops", "An error occurred while submitting the form", "error");
        }
      );
    }
  }

  /**
   * Validates the checkbox group to select at least one
   */
  validateCheckbox(ele) {
    let required = false;
    let touched = false;

    if (ele.mandatory) {
      required = true;

      for (let index = 0; index < ele.options.length; index++) {
        if (ele.options[index].selected) {
          required = false;
        }
      }
    }

    if (required === false) {
      touched = true;
    }

    return required;
  }
  /*   generatePdf() {
    this._dynamicForm
      .generatePDF(this.urlToken, this.selectedLanguage)
      .subscribe(
        response => {},
        error => {}
      );
  } */

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  titleCDC(stringCDC) {
    var value = 0;
    this.pattern.forEach(function (word) {
      value = value + stringCDC.includes(word);
    });
    return value === 1;
  }

  onSubmitAuth(user) {
    let phoneNumber = "+" + this.countryCode + user.phone;
    this._dynamicForm
      .saveInfoSubscribers(
        user.name,
        user.lastname,
        user.company,
        user.email,
        phoneNumber,
        this.formId
      )
      .subscribe(
        (response) => {
          console.log(response);
          swal(
            "Great! ",
            "Successfully submitted. Now you have full access to this form",
            "success"
          );
          this.isPrivate = false;
        },
        (error) => {
          console.log(error);
          swal("Ooops! ", error.error.message + ". Please try again", "error");
        }
      );
  }

  phoneCheck(phone) {
    console.log(phone);
  }

  setCode(e) {
    this.countryCode = e;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveAnswerEnglish() {
    console.log(this.formToSend);
    console.log(this.arrayRow);

    for (let row = 0; row < this.formToSend.length; row++) {
      for (let col = 0; col < this.formToSend[row].formCol.length; col++) {
        for (
          let ele = 0;
          ele < this.formToSend[row].formCol[col].colElement.length;
          ele++
        ) {
          this.formToSend[row].formCol[col].colElement[
            ele
          ].valor = this.arrayRow[row].formCol[col].colElement[ele].valor;
          this.formToSend[row].formCol[col].colElement[
            ele
          ].options = this.arrayRow[row].formCol[col].colElement[ele].options;
        }
      }
    }
    console.log(this.formToSend);
  }
}
