import { Injectable } from "@angular/core";
import { languange } from "../user-forms/models/language.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "../constants";
import { map } from "rxjs/internal/operators/map";
import { Observable } from "rxjs";

@Injectable()
export class DynamicFormService {
  public formRow: Array<any>;
  public formJson: Array<any>;
  public previewJson: Array<any>;
  public tempForm: any;
  public tempFormPreview: any;
  public tempArrayRow: any;
  public editForm: any;
  public elements: String;
  public formPreview: any;
  public colX: number;
  public publicTitle: String;
  public instructions: String;
  public publicTitleCheck: boolean;
  public instructionsCheck: boolean;
  public formName: String;
  public languages: any;
  public publicURL: string;
  public shareScreenToken: string;
  public tokenURL: string;
  public signature: boolean;
  public isPrivate: boolean;
  public goPrint: boolean;
  public isUpdatingForm: boolean;
  public logoBase64: any;
  public themeName: String;
  public logoFile: any;
  public jsonTheme: any;
  public arrayTheme: any;
  public selectedTheme: any;
  public firstElement = true;
  public onBoarding = true;
  public placeholder: any;

  _baseURL: string;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
    this.goPrint = false;
    this.formRow = [];
    this.languages = [];
    this.previewJson = [];
    this.placeholder = [];
    this._baseURL = AppConstants.baseURL;
    this.colX = 0;
    this.isUpdatingForm = false;
    this.shareScreenToken = "";
    this.instructions = "";
    this.themeName = "";
    this.arrayTheme = [];
    this.selectedTheme = "default";
    this.publicTitleCheck = true;
    this.instructionsCheck = true;
    this.jsonTheme = {
      nameTheme: "",
      parent: "",
      email: "",
      dataTheme: {
        backColor1: "",
        backColor2: "",
        boxColor: "",
        textHeader: "",
        textContent: "",
        playColor: "",
        selectedFont: "",
        logoAlign: "",
      },
    };
  }

  getJSON(id, token): Observable<any> {
    const url = `${this._baseURL}/forms/${id}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  getJSONS(token): Observable<any> {
    const url = `${this._baseURL}/forms`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }
  getThemes(token): Observable<any> {
    const url = `${this._baseURL}/listThemes`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  getJSONSpagination(token, page, resultsPerPage): Observable<any> {
    const url = `${this._baseURL}/forms?page=${page}&pagination=${resultsPerPage}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
          page: resultsPerPage,
        }),
      })
      .pipe(map((data) => data));
  }

  getJSONbyToken(jsonToken): Observable<any> {
    const url = `${this._baseURL}/forms/token/${jsonToken}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .pipe(map((data) => data));
  }

  getAnswerbyToken(idAnswer, token): Observable<any> {
    const url = `${this._baseURL}/getAnswer/${idAnswer}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  getJSONbyTokenLanguage(jsonToken, code): Observable<any> {
    const url = `${this._baseURL}/forms/token/${jsonToken}?code=${code}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .pipe(map((data) => data));
  }

  setJSON(json, token): Observable<any> {
    const url = `${this._baseURL}/forms`;
    const body = { json };
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  updateJSON(id, json, token): Observable<any> {
    const url = `${this._baseURL}/forms/update/${id}`;
    const body = { json };
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }
  statusChange(id, token): Observable<any> {
    const url = `${this._baseURL}/forms/statuschange`;
    const body = { id };
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  deleteJSON(id, token): Observable<any> {
    const url = `${this._baseURL}/forms/delete/${id}`;
    return this.http
      .delete(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  searchForm(name, token): Observable<any> {
    const url = `${this._baseURL}/forms/title/${name}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  clearAll() {
    this.previewJson = [];
    this.formPreview = [];
    this.formRow = [];
    this.colX = 0;
  }

  getTTS(id, token): Observable<any> {
    const url = `${this._baseURL}/form-tts/${id}`;
    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token,
          }),
        }
      )
      .pipe(map((data) => data));
  }

  saveImg(token, img): Observable<any> {
    const url = `${this._baseURL}/saveImage`;

    var formData = new FormData();
    formData.append("image", img);

    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  setOnBoarding(token, onboarding): Observable<any> {
    const url = `${this._baseURL}/user/updateOnboarding`;
    const body = { onboarding: "false" };
    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  setAnswer(json): Observable<any> {
    const url = `${this._baseURL}/saveAnswer`;
    const body = { answer: json };
    return this.http.post(url, body, {}).pipe(map((data) => data));
  }

  saveSignature(img): Observable<any> {
    const url = `${this._baseURL}/saveSignature`;

    var formData = new FormData();
    formData.append("image", img);

    return this.http.post(url, formData, {}).pipe(map((data) => data));
  }

  saveInfoSubscribers(
    name,
    last_name,
    company,
    email,
    phone,
    id_form
  ): Observable<any> {
    const url = `${this._baseURL}/saveInfoSubscribers`;
    const body = { name, last_name, company, email, phone, id_form };
    console.log(body);
    return this.http.post(url, body, {}).pipe(map((data) => data));
  }

  getLeads(id_form, token): Observable<any> {
    const url = `${this._baseURL}/forms/subscribers/${id_form}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  listAnswers(token, id, page, pagination): Observable<any> {
    const url = `${this._baseURL}/listAnswers/${id}?page=${page}&pagination=${pagination}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }
}
