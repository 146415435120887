import { colElement } from "./colElement.model";

export class formCol {
  constructor(
    public id: number,
    public rowId: number,
    public percentage: number,
    public colElement: colElement[]
  ) {}
}
