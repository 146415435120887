import { Component, OnInit, Input } from "@angular/core";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-screen-public",
  templateUrl: "./screen-public.component.html",
  styleUrls: ["./screen-public.component.scss"]
})
export class ScreenPublicComponent implements OnInit {
  shareScreenToken: any;
  constructor(
    private _dynamicForm: DynamicFormService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    /* this.route.paramMap.subscribe(params => {
      this.shareScreenToken = params.get("token");
      console.log(this.shareScreenToken);
    }); */
  }
}
