import { Component, OnInit } from "@angular/core";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import {
  trigger,
  state,
  style,
  animate,
  transition
  // ...
} from "@angular/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
@Component({
  selector: "app-new-form-step1",
  templateUrl: "./new-form-step1.component.html",
  styleUrls: ["./new-form-step1.component.scss"],
  animations: [
    trigger("step1", [
      transition(":enter", [
        style({ transform: "translateX(-20%)", opacity: 0 }),
        animate("1s 300ms ease-in")
      ])
    ])
  ]
})
export class NewFormStep1Component implements OnInit {
  isInvalid = true;
  isUpdating = false;
  nameForm = "";
  lengthError: String;
  public id_form;
  constructor(
    private _dynamicForm: DynamicFormService,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private _router: Router
  ) {
    this._route.paramMap.subscribe(params => {
      this.id_form = params.get("id");
      console.log(this.id_form);
    });

    if (this.id_form != null) {
      this.getFormById(this.id_form);
    }
  }

  ngOnInit() {}

  /**
   *Validates that the name form length is 100
   */
  validarName() {
    if (this.nameForm != "") {
      this.isInvalid = false;
      this._dynamicForm.formName = this.nameForm;
    } else this.isInvalid = true;

    if (this.nameForm.length === 100) {
      this.lengthError = "Max length 100 characters";
    } else {
      this.lengthError = null;
    }
  }

  /**
   *Get the name of the form by the DynamicFormService
   */
  getName() {
    this.nameForm = this._dynamicForm.editForm.title;
    this._dynamicForm.formName = this.nameForm;
  }
  getFormById(id) {
    this.clear();
    this._dynamicForm.editForm = [];

    this._dynamicForm.getJSON(id, this.authService.getToken()).subscribe(
      response => {
        this._dynamicForm.signature = response.data.signature;
        this._dynamicForm.isPrivate = !response.data.form_ispublic;

        this._dynamicForm.editForm = response.data;
        this._dynamicForm.jsonTheme.email = this.authService.getCurrentUser().email;
        this._dynamicForm.jsonTheme.dataTheme.playColor = this._dynamicForm.editForm.theme.dataTheme.playColor;
        this._dynamicForm.jsonTheme.nameTheme = this._dynamicForm.editForm.theme.nameTheme;
        this._dynamicForm.jsonTheme.parent = this._dynamicForm.editForm.theme.parent;
        this._dynamicForm.jsonTheme.dataTheme.backColor1 = this._dynamicForm.editForm.theme.dataTheme.backColor1;
        this._dynamicForm.jsonTheme.dataTheme.backColor2 = this._dynamicForm.editForm.theme.dataTheme.backColor2;
        this._dynamicForm.jsonTheme.dataTheme.boxColor = this._dynamicForm.editForm.theme.dataTheme.boxColor;
        this._dynamicForm.jsonTheme.dataTheme.textHeader = this._dynamicForm.editForm.theme.dataTheme.textHeader;
        this._dynamicForm.jsonTheme.dataTheme.textContent = this._dynamicForm.editForm.theme.dataTheme.textContent;
        if (this._dynamicForm.editForm.theme.dataTheme.selectedFont) {
          this._dynamicForm.jsonTheme.dataTheme.selectedFont = this._dynamicForm.editForm.theme.dataTheme.selectedFont;
        }
        if (this._dynamicForm.editForm.theme.dataTheme.logoAlign) {
          this._dynamicForm.jsonTheme.dataTheme.logoAlign = this._dynamicForm.editForm.theme.dataTheme.logoAlign;
        }

        if (this._dynamicForm.jsonTheme.parent === "parent") {
          this._dynamicForm.selectedTheme = this._dynamicForm.jsonTheme.nameTheme;
          this._dynamicForm.themeName = this._dynamicForm.jsonTheme.nameTheme;
        } else {
          this._dynamicForm.selectedTheme = this._dynamicForm.jsonTheme.parent;
          this._dynamicForm.themeName = this._dynamicForm.jsonTheme.parent;
        }
      },
      error => {
        console.log(error);
        this._router.navigate(["/user-forms/dashboard"]);
      },
      () => {
        this._dynamicForm.isUpdatingForm = true;
        //this._router.navigate(["/new-form/step1/"]);
        if (this._dynamicForm.editForm) {
          console.log("Hola");

          this.getName();
          this.isInvalid = false;
          this.isUpdating = true;
        } else {
          this._dynamicForm.formName = "";
        }
      }
    );
  }

  clear() {
    // si se descomentan se borra el formulario del preview
    // this._dynamicForm.formPreview = [];
    // this._dynamicForm.clearAll();

    this._dynamicForm.editForm = {};
    this._dynamicForm.logoFile = null;
    this._dynamicForm.logoBase64 = null;
    this._dynamicForm.isUpdatingForm = false;
    this._dynamicForm.editForm = null;
    this._dynamicForm.publicTitle = null;
    this._dynamicForm.instructions = null;
    this._dynamicForm.themeName = "";
    this._dynamicForm.publicTitleCheck = true;
    this._dynamicForm.instructionsCheck = true;
    this._dynamicForm.jsonTheme = {
      nameTheme: "",
      parent: "",
      email: "",
      dataTheme: {
        backColor1: "",
        backColor2: "",
        boxColor: "",
        textHeader: "",
        textContent: "",
        playColor: ""
      }
    };
  }
}
