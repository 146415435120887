import { Component, OnInit } from "@angular/core";
import VoxeetSdk from "@voxeet/voxeet-web-sdk";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-voxeet",
  templateUrl: "./voxeet.component.html",
  styleUrls: ["./voxeet.component.scss"]
})
export class VoxeetComponent implements OnInit {
  userId: any;
  testRoom: string;
  message: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}
  voxeet = new VoxeetSdk();
  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.testRoom = params.get("token");
      },
      error => {}
    );

    let currentUser = this.authService.getCurrentUser();

    if (!currentUser) {
      window.document.title = "Support";
    }

    this.voxeet
      .initialize("Y2E4c3A4YzZtOHVwOA", "NDczZWR0Mm9yN3M1aDFzdWRuZGRmOHZkZHQ", {
        name: "User Test"
      })
      .then(myUserId => {
        // VoxeetSdk is now initialized, you can create/join a conference

        this.userId = myUserId;
        this.join();
      })
      .catch(error => {
        // An Error has occured, see Error Types
      });

    this.voxeet.on("screenShareStarted", (userId, stream) => {
      var node = document.createElement("video");
      node.setAttribute("id", "screen-share");

      const nav: any = navigator;

      document.body.appendChild(node);

      nav.attachMediaStream(node, stream);

      navigator.activeVRDisplays;
      node.autoplay = true;
      node.muted = true;
    });
    this.voxeet.on("screenShareStopped", () => {
      var node = document.getElementById("screen-share");
      if (node) {
        node.parentNode.removeChild(node);
      }
    });
    this.voxeet.on("messageReceived", msg => {
      var node = document.createElement("div");
      node.innerHTML = msg;

      document.getElementById("messages").appendChild(node);
    });
  }
  /**
   * Initialize the audio in the room
   */
  starAudio() {
    var constraints = { audio: true, video: false };

    this.voxeet
      .joinConference(this.testRoom, { constraints: constraints })
      .then(info => {})
      .catch(error => {});
  }
  /**
   * Method of entering the room
   */
  join() {
    // Constraints example:

    var constraints = { audio: false, video: false };

    this.voxeet
      .joinConference(this.testRoom, { constraints: constraints })
      .then(info => {})
      .catch(error => {});
  }
  /**
   * Method of leaving the room
   */
  leave() {
    this.voxeet.leaveConference().catch(error => {});
  }
  /**
   * Screen sharing method
   */
  screenShare() {
    this.voxeet
      .startScreenShare(["screen"])
      .then(() => {})
      .catch(e => {});
  }
  /**
   * Send message to the room with the enter key
   */
  sendMessageKeyboard(event) {
    if (event.code == "Enter") {
      this.voxeet.sendConferenceMessage(this.message);
    }
  }
  /**
   * Send message to the room with the button "Send"
   */
  sendMessage(event) {
    this.voxeet.sendConferenceMessage(this.message);
  }
  /**
   * Copy the room link to the clipboard
   */
  copyLink(token) {
    var aux = document.createElement("input");
    aux.setAttribute("value", window.location.href);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
  }
}
