import { AnswerDashboardComponent } from "./answers/answer-dashboard/answer-dashboard.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { PublicFormComponent } from "./public-form/public-form.component";
import { ScreenPublicComponent } from "./screen-public/screen-public.component";
import { AnswerFormComponent } from "./answers/answer-form/answer-form.component";
import { LeadsFormComponent } from "./leads-form/leads-form.component";

const routes: Routes = [
  { path: "dashboard", component: DashboardComponent },
  { path: "public-form/:token", component: PublicFormComponent },
  { path: "screen/:token", component: ScreenPublicComponent },
  { path: "answer-form/:id", component: AnswerFormComponent },
  { path: "leads/:id", component: LeadsFormComponent },
  { path: "answers-dashboard/:id", component: AnswerDashboardComponent },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "dashboard",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserFormsRoutingModule {}
