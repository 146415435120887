import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { ChartType, ChartOptions } from "chart.js";
import { MultiDataSet, Label } from "ng2-charts";

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.scss"],
})
export class ChartsComponent implements OnInit {
  @Input() labels: any;
  @Input() data: any;
  @Input() tipo: any;

  public dynamicChartLabels: Label[] = [];
  public dynamicChartData: MultiDataSet = [];
  public dynamicChartType: ChartType = "doughnut";

  public dynamicChartOptions: ChartOptions = {};
  public dynamicChartLegend = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dynamicChartLabels = [];
    this.dynamicChartData = [];
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(this.labels);
    console.log(this.data);
    // console.log("Tipo: " + this.tipo);
    this.dynamicChartType = this.tipo;

    // push labels
    this.labels.forEach((label) => {
      this.dynamicChartLabels.push(label);
    });

    // push data
    let dataArray = [];
    this.data.forEach((item) => {
      dataArray.push(item);
    });

    this.dynamicChartData.push(dataArray);

    this.dynamicChartOptions = {};

    if (this.dynamicChartType === "bar" || this.dynamicChartType === "line") {
      this.dynamicChartOptions = {
        responsive: true,

        scales: {
          xAxes: [{}],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
          },
        },
      };
    }

    if (this.dynamicChartType === "line") {
      this.dynamicChartLegend = false;
    }
  }
  ngOnInit() {}
}
